import { Component, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { FuseLoadingBarModule } from '../../../../@fuse/components/loading-bar';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'empty-layout',
	templateUrl: './empty.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseLoadingBarModule, RouterOutlet],
})
export class EmptyLayoutComponent {}
