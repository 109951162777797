import { Component, input, output } from '@angular/core';
import { UltraPrimaryButtonComponent } from '../../../ultramarine-ui/ultra-primary/ultra-primary.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-quick-action',
	templateUrl: 'quick-action.component.html',
	standalone: true,
	imports: [UltraPrimaryButtonComponent, TranslocoModule, MatIconModule],
	host: {
		class: 'w-full',
	},
})
export class QuickActionComponent {
	title = input<string>();
	icon = input<string>();
	click = output();
}
