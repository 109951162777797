import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseConfigModule } from '@fuse/services/config';
import { LayoutComponent } from 'app/layout/layout.component';
import { SharedModule } from 'app/shared/shared.module';
import { UltramarineLayout } from './components/ultramarine-layout/ultramarine-layout.component';
import { EmptyLayoutComponent } from './components/empty/empty.component';

@NgModule({
	declarations: [LayoutComponent],
	imports: [
		//this inmported here so snackbars can work all over the app
		MatSnackBarModule,
		SharedModule,
		FuseConfigModule,
		EmptyLayoutComponent,
		UltramarineLayout,
	],
	exports: [LayoutComponent, EmptyLayoutComponent, UltramarineLayout],
})
export class LayoutModule {}
