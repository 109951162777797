import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: fuseAnimations,
})
export class AppComponent implements OnInit {
	constructor() {}

	public ngOnInit(): void {}
}
