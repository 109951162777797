<button
	class="w-full p-2 flex items-center justify-center hover:bg-hover"
	(click)="click.emit()"
	color="default">
	<mat-icon class="icon-size-5 mr-2" [svgIcon]="icon()" />
	{{ title() | transloco }}
</button>
<!--
	[content]="title()" -->
<!--

		<button
		class="w-full"
		ultra-primary
		(click)="onQuickActionClick()"
		color="default"
		[svgIcon]="'receipt_long'"
		[content]="'shared|quick-action|lease|title'"></button> -->
