import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, Input, Renderer2 } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterLink } from '@angular/router';

import { fuseAnimations } from '../../../../../@fuse/animations';
import { MENU_NAVIGATION_DATA } from '../../../../core/navigation/menu-bar.data';
import { RouterActiveDirective } from '../../../directives/router-active.directive';
import { QuickActionsComponent } from '../../quick-actions/quick-actions.component';
import { UltraMenuItemComponent } from '../ultra-menu-item/ultra-menu-item.component';
import { UltraMenuItemExpandableComponent } from '../ultra-menu-item-expandable/ultra-menu-item-expandable.component';
import { UltraMenuItemWrapperComponent } from '../ultra-menu-item-wrapper/ultra-menu-item-wrapper.component';
import { UltraButtonComponent } from '../ultra-button/ultra-button.component';
import { UltraPrimaryButtonComponent } from '../ultra-primary/ultra-primary.component';
import { AppConfigService } from '../../../../core/config/app.config.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-ultra-navigation',
	templateUrl: 'ultra-navigation.component.html',
	standalone: true,
	imports: [
		UltraMenuItemComponent,
		UltraMenuItemWrapperComponent,
		UltraMenuItemExpandableComponent,
		UltraPrimaryButtonComponent,
		NgFor,
		NgIf,
		RouterActiveDirective,
		MatIconModule,
		RouterLink,
		NgClass,
		QuickActionsComponent,
	],
	styleUrls: ['ultra-navigation.component.scss'],
	animations: fuseAnimations,
	host: {
		class: 'bg-white dark:bg-neutral-900',
	},
})
export class UltraNavigationComponent {
	constructor(private _r: Router) {}
	navigationData = MENU_NAVIGATION_DATA;
	isOpened: boolean;

	onNavigate(item) {
		this._r.navigate(['admin', item.link]);
	}

	onLogOut() {
		this._r.navigate(['admin-authentication/sign-out']);
	}

	onLogoClick() {
		window.location.reload();
	}

	get currentYear(): number {
		return new Date().getFullYear();
	}
}
