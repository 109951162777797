<!-- <fuse-loading-bar></fuse-loading-bar> -->
<mat-drawer-container class="flex-auto h-full">
	<mat-drawer
		class="w-60"
		[autoFocus]="false"
		[position]="'start'"
		[mode]="drawerMode"
		[(opened)]="showSideMenu">
		<app-ultra-navigation>
			<app-pop-state></app-pop-state>
			<!-- <app-user-portal></app-user-portal> -->
			<button
				ultra-button
				(click)="toggleSideMenu()"
				[svgIcon]="'menu'"></button>
		</app-ultra-navigation>
	</mat-drawer>
	<mat-drawer-content class="flex flex-col">
		<div
			class="flex bg-white dark:bg-neutral-900 items-center w-full h-12 px-2 z-49 border-b">
			<button
				class="mr-2"
				ultra-button
				[ngClass]="showSideMenu ? 'hidden' : ''"
				(click)="toggleSideMenu()"
				[svgIcon]="'menu'"></button>
			<h4
				*ngIf="operator()"
				class="dark:bg-neutral-800 border bg-slate-100 px-2 py-1 rounded-lg">
				{{ operator() }}
			</h4>
			<languages class="ml-auto"></languages>
			<button
				ultra-primary
				class="mx-1"
				[content]="'shared|live-chat'"
				[svgIcon]="'chat'"
				(click)="toggleChat()"></button>
			<button
				ultra-button
				(click)="toggleDarkMode()"
				[svgIcon]="themeIcon()"></button>
		</div>
		<div class="flex flex-col flex-auto">
			<router-outlet />
		</div>
	</mat-drawer-content>
</mat-drawer-container>
