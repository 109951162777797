<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
	<!-- Content -->
	<div class="flex flex-col flex-auto">
		<router-outlet />
	</div>
</div>
