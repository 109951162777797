import * as Sentry from '@sentry/angular';

import { AppConfigService } from './app/core/config/app.config.service';

// Sentry.init({
// 	dsn: 'https://4f3307b448100ec5e436e05df12462cd@o4507831547789312.ingest.de.sentry.io/4507833224069200',
// 	integrations: [
// 		Sentry.browserTracingIntegration(),
// 		Sentry.replayIntegration(),
// 	],
// 	release: 'ultramarine-api@0.1.0',
// 	tracesSampleRate: 0.1,
// 	tracePropagationTargets: [
// 		'localhost',
// 		/^https:\/\/api\.(dev|staging|ultra-marine)\.com/,
// 	],
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 0.1,
// 	// debug: true,
// });

export function initializeSentry(configService: AppConfigService): () => void {
	return () => {
		Sentry.init({
			environment: configService.config.sentryEnv,
			dsn: configService.config.sentryDsn,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration(),
			],
			release: `ultramarine-app@${configService.config.buildVersion}`,
			tracesSampleRate: configService.config.sentryTracesSampleRate,
			tracePropagationTargets: [
				'localhost',
				/^https:\/\/api\.(dev|staging|ultra-marine)\.com/,
			],
			replaysSessionSampleRate:
				configService.config.sentryReplaysSessionSampleRate,
			replaysOnErrorSampleRate:
				configService.config.sentryReplaysOnErrorSampleRate,
			// debug: configService.config.appEnv === 'development',
		});
	};
}
