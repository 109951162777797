import { UltraNavigationItem } from '../../shared/models/ultra-navigation-item.model';
import { FEATURES } from '../features/features';

export const MENU_NAVIGATION_DATA: UltraNavigationItem[] = [
	{
		id: 'home',
		title: 'homepage|title',
		tooltip: 'Home',
		type: 'basic',
		icon: 'home',
		link: 'home',
	},
	{
		id: 'administration',
		title: 'administration|title',
		type: 'aside',
		icon: 'all_inbox',
		children: [
			// {
			// 	id: 'administration.users',
			// 	title: 'Users',
			// 	type: 'basic',
			// 	icon: 'person_pin',
			// 	link: 'users',
			// },
			...(FEATURES.locations
				? [
						{
							id: 'administration.locations',
							title: 'locations|title',
							type: 'basic',
							icon: 'pin_drop',
							link: 'locations',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.staff
				? [
						{
							id: 'administration.staff',
							title: 'staff|title',
							type: 'basic',
							icon: 'perm_contact_calendar',
							link: 'staff',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.customers
				? [
						{
							id: 'administration.customers',
							title: 'customers|title',
							type: 'basic',
							icon: 'groups',
							link: 'customers',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.suppliers
				? [
						{
							id: 'administration.suppliers',
							title: 'suppliers|title',
							type: 'basic',
							icon: 'business_center',
							link: 'suppliers',
						} as UltraNavigationItem,
					]
				: []),
		],
	},
	{
		id: 'fleet',
		title: 'fleet|title',
		type: 'aside',
		icon: 'directions_car',
		children: [
			...(FEATURES.vehicles
				? [
						{
							id: 'fleet.vehicles',
							title: 'vehicles|title',
							type: 'basic',
							icon: 'directions_car',
							link: 'vehicles',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.vehicleRoadTaxes
				? [
						{
							id: 'fleet.vehicle-road-taxes',
							title: 'road-taxes|title',
							type: 'basic',
							icon: 'account_balance',
							link: 'vehicle-road-taxes',
							disabled: false,
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.vehicleRoadWorthyChecks
				? [
						{
							id: 'fleet.vehicle-road-worthy-checks',
							title: 'road-worthy-checks|title',
							type: 'basic',
							icon: 'inventory',
							link: 'vehicle-road-worthy-checks',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.vehicleDamages
				? [
						{
							id: 'fleet.vehicle-damages',
							title: 'damages|title',
							type: 'basic',
							icon: 'flash_on',
							link: 'vehicle-damages',
							disabled: false,
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.vehicleCategories
				? [
						{
							id: 'fleet.vehicle-categories',
							title: 'categories|title',
							type: 'basic',
							icon: 'category',
							link: 'vehicle-categories',
						} as UltraNavigationItem,
					]
				: []),
		],
	},
	{
		id: 'operations',
		title: 'operations|title',
		type: 'aside',
		icon: 'work_outline',
		children: [
			...(FEATURES.vehicleMaintenances
				? [
						{
							id: 'fleet.vehicle-maintenances',
							title: 'maintenances|title',
							type: 'basic',
							icon: 'car_repair',
							link: 'vehicle-maintenances',
						} as UltraNavigationItem,
					]
				: []),
			// ...(FEATURES.rentalReservations
			// 	? [
			// 			{
			// 				id: 'operations.rental-reservations',
			// 				title: 'rental-reservations|title',
			// 				type: 'basic',
			// 				icon: 'hail',
			// 				link: 'rental-reservations',
			// 			} as UltraNavigationItem,
			// 		]
			// 	: []),
			...(FEATURES.rentalAgreements
				? [
						{
							id: 'operations.rental-agreements',
							title: 'rental-agreements|title',
							type: 'basic',
							icon: 'car_rental',
							link: 'rental-agreements',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.leaseAgreements
				? [
						{
							id: 'operations.lease-agreements',
							title: 'lease-agreements|title',
							type: 'basic',
							icon: 'receipt_long',
							link: 'lease-agreements',
						} as UltraNavigationItem,
					]
				: []),
			...(FEATURES.trainingAgreements
				? [
						{
							id: 'operations.training-agreements',
							title: 'training-agreements|title',
							type: 'basic',
							icon: 'school',
							link: 'training-agreements',
						} as UltraNavigationItem,
					]
				: []),
		],
	},
	// {
	// 	id: 'integrations',
	// 	title: 'Integrations',
	// 	tooltip: 'Integrations',
	// 	type: 'aside',
	// 	icon: 'settings_ethernet',
	// 	children: [
	// 		{
	// 			id: 'integrations.api-keys',
	// 			title: 'API Keys',
	// 			type: 'basic',
	// 			icon: 'vpn_key',
	// 			link: 'api-keys',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'integrations.webhooks',
	// 			title: 'Webhooks',
	// 			type: 'basic',
	// 			icon: 'cast_connected',
	// 			link: 'webhooks',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'integrations.hub',
	// 			title: 'Integrations Hub',
	// 			type: 'basic',
	// 			icon: 'settings_input_hdmi',
	// 			link: 'integrations-hub',
	// 			disabled: true,
	// 		},
	// 		{
	// 			type: 'divider',
	// 		},
	// 		{
	// 			id: 'integrations.data-import',
	// 			title: 'Data Import',
	// 			type: 'basic',
	// 			icon: 'cloud_upload',
	// 			link: 'integrations-data-import',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'integrations.data-export',
	// 			title: 'Data Export',
	// 			type: 'basic',
	// 			icon: 'cloud_download',
	// 			link: 'integrations-data-export',
	// 			disabled: true,
	// 		},
	// 	],
	// },
	// {
	// 	id: 'reports',
	// 	title: 'Reports',
	// 	tooltip: 'Reports',
	// 	type: 'aside',
	// 	icon: 'insert_chart_outlined',
	// 	children: [
	// 		{
	// 			id: 'reports.administration-reports',
	// 			title: 'Administration Reports',
	// 			type: 'basic',
	// 			icon: 'all_inbox',
	// 			link: 'administration-reports',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'reports.fleet-reports',
	// 			title: 'Fleet Reports',
	// 			type: 'basic',
	// 			icon: 'directions_boat',
	// 			link: 'fleet-reports',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'reports.operations-reports',
	// 			title: 'Operations Reports',
	// 			type: 'basic',
	// 			icon: 'work_outline',
	// 			link: 'operations-reports',
	// 			disabled: true,
	// 		},
	// 		{
	// 			type: 'divider',
	// 		},
	// 		{
	// 			id: 'reports.usage-audit-logs',
	// 			title: 'Usage & Audit Logs',
	// 			type: 'basic',
	// 			icon: 'history',
	// 			link: 'usage-audit-logs',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'reports.account-billing-reports',
	// 			title: 'Account & Billing Reports',
	// 			type: 'basic',
	// 			icon: 'account_balance_wallet',
	// 			link: 'account-billing-reports',
	// 			disabled: true,
	// 		},
	// 		{
	// 			type: 'divider',
	// 		},
	// 		{
	// 			id: 'reports.integrations-reports',
	// 			title: 'Integrations Reports',
	// 			type: 'basic',
	// 			icon: 'settings_ethernet',
	// 			link: 'integrations-reports',
	// 			disabled: true,
	// 		},
	// 	],
	// },
	// {
	// 	id: 'platform',
	// 	title: 'Platform',
	// 	tooltip: 'Platform',
	// 	type: 'aside',
	// 	icon: 'cloud',
	// 	children: [
	// 		{
	// 			id: 'platform.help-center',
	// 			title: 'Help Center',
	// 			type: 'basic',
	// 			icon: 'support_agent',
	// 			link: 'support',
	// 			disabled: true,
	// 		},
	// 		{
	// 			type: 'divider',
	// 		},
	// 		{
	// 			id: 'platform.privacy-policy',
	// 			title: 'Privacy Policy',
	// 			type: 'basic',
	// 			icon: 'privacy_tip',
	// 			link: 'privacy-policy',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'platform.terms-of-service',
	// 			title: 'Terms of Service',
	// 			type: 'basic',
	// 			icon: 'description',
	// 			link: 'terms-of-service',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'platform.licenses',
	// 			title: 'Licenses',
	// 			type: 'basic',
	// 			icon: 'verified',
	// 			link: 'licenses',
	// 			disabled: true,
	// 		},
	// 		{
	// 			type: 'divider',
	// 		},
	// 		{
	// 			id: 'platform.release-notes',
	// 			title: 'Release Notes',
	// 			type: 'basic',
	// 			icon: 'rss_feed',
	// 			link: 'release-notes',
	// 			disabled: true,
	// 		},
	// 		{
	// 			id: 'platform.about',
	// 			title: 'About',
	// 			type: 'basic',
	// 			icon: 'info',
	// 			link: 'about',
	// 			disabled: true,
	// 		},
	// 	],
	// },
];
