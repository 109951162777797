import {
	Component,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewEncapsulation,
	inject,
	signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subject, takeUntil, throwIfEmpty } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations';
import { UltraButtonComponent } from '../../../shared/components/ultramarine-ui/ultra-button/ultra-button.component';
import { UserPortalComponent } from './components/user-portal/user-portal.component';
import { UltraNavigationComponent } from '../../../shared/components/ultramarine-ui/ultra-navigation/ultra-navigation.component';
import { PopStateComponent } from '../../../shared/components/pop-state/pop-state.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LanguagesModule } from './components/languages/languages.module';
import { MediaWatcherService } from '../../../shared/services/media-watcher.service';
import { NgClass, NgIf } from '@angular/common';
import { AppContextService } from '../../../shared/services/app-context.service';
import { AppConfigService } from '../../../core/config/app.config.service';
import { UltraPrimaryButtonComponent } from '../../../shared/components/ultramarine-ui/ultra-primary/ultra-primary.component';

@Component({
	selector: 'app-ultramarine-layout',
	templateUrl: './ultramarine-layout.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['ultramarine-layout.component.scss'],
	animations: fuseAnimations,
	standalone: true,
	imports: [
		LanguagesModule,
		PopStateComponent,
		MatSidenavModule,
		UltraNavigationComponent,
		UserPortalComponent,
		UltraButtonComponent,
		RouterModule,
		NgClass,
		NgIf,
		UltraPrimaryButtonComponent,
	],
})
export class UltramarineLayout implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	isScreenSmall: boolean;
	showSideMenu: boolean = true;
	public drawerMode: 'over' | 'side';
	private _mediaWatcherService = inject(MediaWatcherService);
	themeIcon = signal('dark_mode');
	operator = signal('');
	appContext = inject(AppContextService);
	config = inject(AppConfigService);
	renderer = inject(Renderer2);
	localWindow = window as any;

	constructor() {
		window.addEventListener('chatwoot:ready', function () {
			this.isChatwootReady = true;
			const localWindow = window as any;
			localWindow.$chatwoot.toggleBubbleVisibility('hide');
		});
	}
	ngOnInit(): void {
		this.appContext.appConfig.config$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res) => {
				this.operator.set(res.apiAccountContactCompanyName);
				this.loadChatwootSDK(res.chatwootBaseUrl, res.chatwootWebsiteToken);
			});
		const savedTheme = localStorage.getItem('theme');
		const bodyElement = document.body;

		// If 'dark' is saved in localStorage, apply the dark theme
		if (savedTheme === 'dark') {
			this.themeIcon.set('light_mode');
			bodyElement.classList.add('dark');
		} else {
			this.themeIcon.set('dark_mode');
		}
		// Otherwise, no class is applied (default light mode)
		this._mediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				this.isScreenSmall = !matchingAliases.includes('md');
				if (this.isScreenSmall) {
					this.showSideMenu = false;
					this.drawerMode = 'over';
				} else {
					this.showSideMenu = true;
					this.drawerMode = 'side';
				}
			});
	}

	toggleChat() {
		if (!this.localWindow.$chatwoot.isOpen) {
			this.localWindow.$chatwoot.toggle('open');
		} else {
			this.localWindow.$chatwoot.toggle('close');
		}
	}

	loadChatwootSDK(baseUrl: string, websiteToken: string) {
		const script = this.renderer.createElement('script');
		script.src = baseUrl + '/packs/js/sdk.js';
		script.defer = true;
		script.async = true;
		script.onload = () => {
			(window as any).chatwootSDK.run({
				websiteToken: websiteToken,
				baseUrl: baseUrl,
			});
		};
		this.renderer.appendChild(document.body, script);
	}

	toggleSideMenu() {
		this.showSideMenu = !this.showSideMenu;
	}

	toggleDarkMode() {
		const bodyElement = document.body;
		if (bodyElement.classList.contains('dark')) {
			bodyElement.classList.remove('dark');
			localStorage.setItem('theme', 'light');
			this.themeIcon.set('dark_mode');
		} else {
			bodyElement.classList.add('dark');
			localStorage.setItem('theme', 'dark');
			this.themeIcon.set('light_mode');
		}
	}
	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
