import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { RequestResponseHeaderEnum } from 'ultramarine-shared';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		// Clone the request and add the timezone header
		const headers = req.headers.set(
			RequestResponseHeaderEnum.TIMEZONE,
			DateTime.local().zoneName,
		);

		const modifiedReq = req.clone({ headers });

		// Pass the modified request to the next handler
		return next.handle(modifiedReq);
	}
}
