import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolver';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

import { AuthGuard } from './core/auth/guards/auth.guard';
import { FEATURES } from './core/features/features';
import { RegisterComponent } from './modules/register/register.component';

export const appRoutes: Route[] = [
	{ path: '', pathMatch: 'full', redirectTo: 'admin/home' },
	{ path: 'admin', pathMatch: 'full', redirectTo: 'admin/home' },

	// Redirect signed-in user to the '/dashboard-default'
	//
	// After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
	// path. Below is another redirection for that path to redirect the user to the desired
	// location. This is a small convenience to keep all main routes together here on this file.
	{
		path: 'signed-in-redirect',
		pathMatch: 'full',
		redirectTo: 'admin/home',
	},

	{
		path: 'register',
		component: RegisterComponent,
	},

	// Auth routes for guests
	{
		path: 'authentication',
		canMatch: [NoAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'sign-in',
				loadComponent: () =>
					import('app/modules/auth/sign-in/sign-in.component').then(
						(m) => m.AuthSignInComponent,
					),
			},
			{
				path: 'change-password',
				loadComponent: () =>
					import(
						'app/modules/auth/change-password/change-password.component'
					).then((m) => m.ChangePasswordComponent),
			},
		],
	},

	// Auth routes for authenticated users
	{
		path: 'admin-authentication',
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'sign-out',
				loadChildren: () =>
					import('app/modules/auth/sign-out/sign-out.module').then(
						(m) => m.AuthSignOutModule,
					),
			},
		],
	},

	{
		path: 'admin',
		canMatch: [AuthGuard],
		component: LayoutComponent,
		resolve: { data: () => inject(InitialDataResolver).resolve() },
		children: [
			// Modules
			// {
			// 	path: 'dashboards',
			// 	loadChildren: () =>
			// 		import('app/modules/admin/dashboard/dashboard.module').then(
			// 			(m) => m.DashboardModule
			// 		)
			// },
			{
				path: 'home',
				loadComponent: () =>
					import('app/modules/admin/home/home.component').then(
						(c) => c.HomeComponent,
					),
			},
			{
				path: 'account',
				loadChildren: () =>
					import('app/modules/admin/account/account.module').then(
						(m) => m.AccountModule,
					),
			},
			{
				path: 'settings',
				loadChildren: () =>
					import('app/modules/admin/settings/settings.module').then(
						(m) => m.SettingsModule,
					),
			},
			...(FEATURES.locations
				? [
						{
							path: 'locations',
							loadChildren: () =>
								import('app/modules/admin/locations/locations.module').then(
									(m) => m.LocationsModule,
								),
						},
					]
				: []),
			...(FEATURES.staff
				? [
						{
							path: 'staff',
							loadChildren: () =>
								import('app/modules/admin/staff/staff.module').then(
									(m) => m.StaffModule,
								),
						},
					]
				: []),
			...(FEATURES.customers
				? [
						{
							path: 'customers',
							loadChildren: () =>
								import('app/modules/admin/customers/customers.module').then(
									(m) => m.CustomersModule,
								),
						},
					]
				: []),
			...(FEATURES.suppliers
				? [
						{
							path: 'suppliers',
							loadChildren: () =>
								import('app/modules/admin/suppliers/suppliers.module').then(
									(m) => m.SuppliersModule,
								),
						},
					]
				: []),
			// {
			// 	path: 'users',
			// 	loadChildren: () =>
			// 		import('app/modules/admin/users/users.module').then(
			// 			(m) => m.UsersModule,
			// 		),
			// },
			// {
			// 	path: 'pricing-profiles',
			// 	loadChildren: () =>
			// 		import(
			// 			'app/modules/admin/pricing-profiles/pricing-profiles.module'
			// 		).then((m) => m.PricingProfilesModule),
			// },
			...(FEATURES.vehicleDamages
				? [
						{
							path: 'vehicle-damages',
							loadChildren: () =>
								import(
									'app/modules/admin/vehicle-damages/vehicle-damages.module'
								).then((m) => m.VehicleDamagesModule),
						},
					]
				: []),
			// ...(FEATURES.rentalReservations
			// 	? [
			// 			{
			// 				path: 'rental-reservations',
			// 				loadChildren: () =>
			// 					import(
			// 						'app/modules/admin/rental-reservations/rental-reservations.module'
			// 					).then((m) => m.RentalReservationsModule),
			// 			},
			// 		]
			// 	: []),
			...(FEATURES.rentalAgreements
				? [
						{
							path: 'rental-agreements',
							loadChildren: () =>
								import(
									'app/modules/admin/rental-agreements/rental-agreements.module'
								).then((m) => m.RentalAgreementsModule),
						},
					]
				: []),
			...(FEATURES.leaseAgreements
				? [
						{
							path: 'lease-agreements',
							loadChildren: () =>
								import(
									'app/modules/admin/lease-agreements/lease-agreements.module'
								).then((m) => m.LeaseAgreementsModule),
						},
					]
				: []),
			...(FEATURES.trainingAgreements
				? [
						{
							path: 'training-agreements',
							loadChildren: () =>
								import(
									'app/modules/admin/training-agreements/training-agreements.module'
								).then((m) => m.TrainingAgreementsModule),
						},
					]
				: []),
			...(FEATURES.vehicles
				? [
						{
							path: 'vehicles',
							loadChildren: () =>
								import('app/modules/admin/vehicles/vehicles.module').then(
									(m) => m.VehiclesModule,
								),
						},
					]
				: []),
			...(FEATURES.vehicleMaintenances
				? [
						{
							path: 'vehicle-maintenances',
							loadChildren: () =>
								import(
									'app/modules/admin/vehicle-maintenances/vehicle-maintenances.module'
								).then((m) => m.VehicleMaintenancesModule),
						},
					]
				: []),
			...(FEATURES.vehicleRoadTaxes
				? [
						{
							path: 'vehicle-road-taxes',
							loadChildren: () =>
								import(
									'app/modules/admin/vehicle-road-taxes/vehicle-road-taxes.module'
								).then((m) => m.VehicleRoadTaxesModule),
						},
					]
				: []),
			...(FEATURES.vehicleRoadWorthyChecks
				? [
						{
							path: 'vehicle-road-worthy-checks',
							loadChildren: () =>
								import(
									'app/modules/admin/vehicle-road-worthy-checks/vehicle-road-worthy-checks.module'
								).then((m) => m.VehicleRoadWorthyChecksModule),
						},
					]
				: []),
			...(FEATURES.vehicleCategories
				? [
						{
							path: 'vehicle-categories',
							loadChildren: () =>
								import(
									'app/modules/admin/vehicle-categories/vehicle-categories.module'
								).then((m) => m.VehicleCategoriesModule),
						},
					]
				: []),
			// {
			// 	path: 'calendar',
			// 	loadChildren: () =>
			// 		import('app/modules/admin/calendar/ultramarine-calendar.module').then(
			// 			(m) => m.UltramarineCalendarModule,
			// 		),
			// },
			// {
			// 	path: 'reports',
			// 	loadChildren: () =>
			// 		import('app/modules/admin/reports/reports.module').then(
			// 			(m) => m.FleetDetailsModule,
			// 		),
			// },
			// {
			// 	path: 'help-center',
			// 	loadChildren: () =>
			// 		import('app/modules/admin/help-center/help-center.module').then(
			// 			(m) => m.HelpCenterModule,
			// 		),
			// },
			// {
			// 	path: 'changelog',
			// 	loadChildren: () =>
			// 		import('app/modules/admin/changelog/changelog.module').then(
			// 			(m) => m.ChangelogModule,
			// 		),
			// },
		],
	},
	// Errors & Catch all
	{
		path: 'errors',
		loadChildren: () =>
			import('app/modules/errors/errors.module').then((m) => m.ErrorsModule),
	},

	{ path: '**', redirectTo: 'errors/error-404' },
];
