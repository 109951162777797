import { Layout } from 'app/layout/layout.types';

export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;

export interface FuseConfig {
	layout: Layout;
	scheme: Scheme;
	screens: Screens;
	theme: Theme;
}

export const FUSE_CONFIG: FuseConfig = {
	layout: 'thin',
	scheme: 'light',
	screens: {
		sm: '600px',
		md: '960px',
		lg: '1280px',
		xl: '1440px',
	},
	theme: 'theme-brand',
};
