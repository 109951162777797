<div
	class="flex flex-col h-full max-h-screen bg-white dark:bg-neutral-900 relative">
	<div id="header">
		<div
			class="flex flex-col gap-6 justify-between border-b p-2 bg-gray-100 dark:bg-[#141416]">
			<div class="flex justify-between">
				<img
					(click)="onLogoClick()"
					class="w-11 h-9 p-1 py-2 bg-slate-200 dark:bg-slate-900 box-shadow rounded-xl"
					src="assets/images/logo/ultramarine.png"
					alt="Logo image" />
				<div class="flex">
					<ng-content></ng-content>
				</div>
			</div>
			<div class="flex gap-2">
				<button
					class="w-full bg-white dark:bg-neutral-900"
					ultra-primary
					color="default"
					[disabled]="true"
					[svgIcon]="'settings'"></button>
				<button
					[disabled]="true"
					class="w-full bg-white dark:bg-neutral-900"
					ultra-primary
					color="default"
					[svgIcon]="'business'"></button>
				<button
					(click)="onLogOut()"
					class="w-full bg-white dark:bg-neutral-900"
					ultra-primary
					color="default"
					[svgIcon]="'logout'"></button>
			</div>
		</div>
	</div>

	<div
		id="body"
		class="flex self-start flex-col w-full overflow-y-auto p-1 pb-20 gap-0.5">
		<div
			[routerActive]="item.link"
			(click)="item.link ? onNavigate(item) : null"
			*ngFor="let item of navigationData"
			ultra-menu-item-expandable
			[content]="item.title"
			[type]="item.type"
			[svgIcon]="item.icon">
			<ng-container *ngIf="item.children">
				<ng-container *ngFor="let item of item.children">
					<a
						[routerLink]="['../admin', item.link]"
						[routerActive]="item.link"
						class="w-full pl-4 !mx-0 max-w-full"
						*ngIf="item.type === 'basic'"
						[content]="item.title"
						[svgIcon]="item.icon"
						[disabled]="item.disabled"
						ultra-menu-item></a>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div
		id="footer"
		class="absolute bottom-0 w-full text-md text-center bg-white dark:bg-neutral-900">
		<div>
			<app-quick-actions></app-quick-actions>
		</div>
		<div class="border-t py-2">
			<span class="text-gray-500">Ultramarine</span>
			<span class="text-gray-500"> © SSC</span>
			<span class="ml-1 text-gray-500">{{ currentYear }}</span>
		</div>
	</div>
</div>
