<div class="flex border-t">
	<app-quick-action
		(click)="onRent()"
		class="border-r"
		[title]="'shared|quick-action|rent|title'"
		[icon]="'car_rental'" />
	<app-quick-action
		(click)="onLease()"
		[title]="'shared|quick-action|lease|title'"
		[icon]="'receipt_long'" />
</div>
