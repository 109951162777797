import { Component, inject } from '@angular/core';
import { QuickActionComponent } from './components/quick-action/quick-action.component';
import { Router } from '@angular/router';
import { ModulesEnum } from '../list-table/modules.enum';

@Component({
	selector: 'app-quick-actions',
	templateUrl: 'quick-actions.component.html',
	standalone: true,
	imports: [QuickActionComponent],
})
export class QuickActionsComponent {
	router = inject(Router);
	onRent() {
		this.router.navigate(['admin', ModulesEnum.RENTAL_AGREEMENTS, 'new']);
	}
	onLease() {
		this.router.navigate(['admin', ModulesEnum.LEASE_AGREEMENTS, 'new']);
	}
}
